<template>
  <article
    v-if="!loading"
    class="user-edit box"
  >
    <VCard class="user-edit__body">
      <h1 class="page-title">
        {{ documentTitle }}
      </h1>

      <div class="user-edit__content">
        <VForm
          ref="form"
          class="fill-height"
          lazy-validation
          @submit.prevent="save"
        >
          <VCard flat>
            <VCardText>
              <VRow>
                <VCol
                  v-if="preparedFields.gender?.isEmpty"
                  cols="12"
                >
                  <VRadioGroup
                    v-model="preparedFields.gender.value"
                    :label="$t('gender.one')"
                    row
                    :rules="[(v) => !!v || 'Заполните поле']"
                    class="required"
                  >
                    <VRadio
                      :label="$t('gender_female.one')"
                      value="f"
                    />
                    <VRadio
                      :label="$t('gender_male.one')"
                      value="m"
                    />
                  </VRadioGroup>
                </VCol>

                <VCol
                  v-if="preparedFields.dob?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model="preparedFields.dob.value"
                    v-mask="'##.##.####'"
                    placeholder="ДД.ММ.ГГГГ"
                    outlined
                    :label="$t('dob.one')"
                    class="required"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 63 ||
                        'В дате рождения не должно быть больше 63 символов',
                    ]"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.citizenship?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VAutocomplete
                    v-model="preparedFields.citizenship.value"
                    outlined
                    :items="citizenshipItems"
                    item-text="title"
                    item-value="code"
                    :label="$t('citizenship.one')"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.inn?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model.trim="preparedFields.inn.value"
                    outlined
                    :label="$t('inn.one')"
                    placeholder="AA BB CCCCC D или AA BB CCCCCC DD"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) => validateINN(v)
                    ]"
                    class="required"
                    @input="updateINN"
                  />
                </VCol>
                <VCol
                  v-if="preparedFields.snils?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    ref="snils"
                    v-model.trim="preparedFields.snils.value"
                    v-mask="'###-###-### ##'"
                    outlined
                    :label="$t('snils.one')"
                    placeholder="XXX-XXX-XXX YY"
                    :rules="[(v) => validateSNILS(v)]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.phone?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model.trim="preparedFields.phone.value"
                    outlined
                    :label="$t('phones.one')"
                    type="tel"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 254 ||
                        'Это поле должно быть не больше 254 символов',
                    ]"
                    class="required"
                  />
                </VCol>
                <VCol
                  v-if="preparedFields.email?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model.trim="preparedFields.email.value"
                    outlined
                    :label="$t('emails.one')"
                    type="email"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        !(v || '').length ||
                        /.+@.+/.test(v) ||
                        'Невалидный email',
                      (v) =>
                        (v || '').trim().length <= 254 ||
                        'Это поле должно быть не больше 254 символов',
                    ]"
                    class="required"
                  />
                </VCol>
                <VCol
                  v-if="preparedFields.passport?.isEmpty"
                  cols="12"
                >
                  <VTextarea
                    v-model.trim="preparedFields.passport.value"
                    outlined
                    :label="$t('user_profile_passport_info.one')"
                    :placeholder="
                      $t('user_profile_passport_info_placeholder.one')
                    "
                    rows="3"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 254 ||
                        'Это поле должно быть не больше 254 символов',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.locality?.isEmpty"
                  cols="12"
                >
                  <VTextField
                    v-model.trim="preparedFields.locality.value"
                    outlined
                    :label="$t('locality_of_a_person.one')"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 1023 ||
                        'Это поле должно быть не больше 1023 символов',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.address?.isEmpty"
                  cols="12"
                >
                  <VTextarea
                    v-model.trim="preparedFields.address.value"
                    outlined
                    :label="$t('address_of_a_person.one')"
                    rows="3"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 1023 ||
                        'Это поле должно быть не больше 1023 символов',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.employer?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model.trim="preparedFields.employer.value"
                    outlined
                    :label="$t('employers.one')"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 254 ||
                        'Это поле должно быть не больше 254 символов',
                    ]"
                    class="required"
                  />
                </VCol>
                <VCol
                  v-if="preparedFields.position?.isEmpty"
                  cols="12"
                  md="6"
                >
                  <VTextField
                    v-model.trim="preparedFields.position.value"
                    outlined
                    :label="$t('user_profile_position.one')"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 254 ||
                        'Это поле должно быть не больше 254 символов',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.education?.isEmpty"
                  cols="12"
                >
                  <VTextarea
                    v-model.trim="preparedFields.education.value"
                    outlined
                    :label="$t('information_education.one')"
                    rows="3"
                    :rules="[
                      (v) => (v || '').trim().length > 0 || 'Заполните поле',
                      (v) =>
                        (v || '').trim().length <= 1023 ||
                        'Это поле должно быть не больше 1023 символов',
                    ]"
                    class="required"
                  />
                </VCol>

                <VCol
                  v-if="preparedFields.previous_certificate?.isEmpty"
                  cols="12"
                >
                  <VCard color="#f5f5f5">
                    <VCardTitle>
                      <span class="required">{{
                        $t("information_doc_education_of_the_user.one")
                      }}:</span>
                    </VCardTitle>

                    <VCardText>
                      <VCard
                        v-for="(certificate, idx) in preparedFields.previous_certificate.value"
                        :key="idx"
                        :class="{'mb-4': idx !== preparedFields.previous_certificate.value.length, }"
                      >
                        <VListItem>
                          <VListItemContent />
                          <VListItemAction>
                            <VBtn
                              v-if="preparedFields.previous_certificate.value?.length > 1"
                              icon
                              color="error"
                              :title="$t('delete.one')"
                              @click="deleteCertificate(idx)"
                            >
                              <VIcon>close</VIcon>
                            </VBtn>
                          </VListItemAction>
                        </VListItem>
                        <VDivider />
                        <VCardText>
                          <VRow>
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <VSelect
                                v-model="certificate.level"
                                dense
                                hide-details
                                :items="certificateLevels"
                                clearable
                                :label="$t('education_level.one')"
                                outlined
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <VTextField
                                v-model="certificate.fullname"
                                dense
                                hide-details
                                outlined
                                :label="$t('certificate_full_name.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="4"
                            >
                              <VTextField
                                v-model="certificate.series"
                                dense
                                hide-details
                                outlined
                                :label="$t('series.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="4"
                            >
                              <VTextField
                                v-model="certificate.number"
                                dense
                                hide-details
                                outlined
                                :label="$t('numbers.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="4"
                            >
                              <VTextField
                                v-model="certificate.signed_at"
                                dense
                                hide-details
                                outlined
                                :label="$t('signed_at.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <VTextField
                                v-model="certificate.edu"
                                dense
                                hide-details
                                outlined
                                :label="$t('educational_institution.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                            <VCol
                              cols="12"
                              md="6"
                            >
                              <VTextField
                                v-model="certificate.edu_level"
                                dense
                                hide-details
                                outlined
                                :label="$t('educational_institution_level.one')"
                                :rules="[
                                  (v) =>
                                    (v || '').trim().length > 0 ||
                                    'Заполните поле',
                                ]"
                                class="required"
                              />
                            </VCol>
                          </VRow>
                        </VCardText>
                      </VCard>
                    </VCardText>
                    <VCardActions>
                      <VBtn
                        color="#fff"
                        depressed
                        @click="addCertificate"
                      >
                        {{ $t("add.one") }} {{ $t("documents.one") }}
                      </VBtn>
                    </VCardActions>
                  </VCard>
                </VCol>
              </VRow>
            </VCardText>

            <VCardActions>
              <VBtn
                depressed
                type="submit"
                :loading="isSubmitting"
                :large="$vuetify.breakpoint.lgAndUp"
                color="primary"
                @click="save('work-form')"
              >
                <VIcon left>
                  save
                </VIcon> {{ $t("save.one") }}
              </VBtn>
            </VCardActions>
          </VCard>
        </VForm>
      </div>
    </VCard>
  </article>

  <UPlaceholder v-else />
</template>

<script>
import { mask } from '@titou10/v-mask'
import { mapGetters, mapActions } from 'vuex'

import innValidator from '@/mixins/innValidator'
import documentTitle from '@/mixins/documentTitle'
import { updateUser, getUserSettings, getUser } from '@/api/api'
import store from '@/store/store'
import { COUNTRIES } from '@/utils/countries'
import { validateSNILS } from '../utils/snilsValidator'
import { UDate } from '@/utils/date'
import * as actions from '@/store/actions/types'
import * as getters from '@/store/getters/types'

import UPlaceholder from '@components/UPlaceholder.vue'

class Profile {
  constructor (account = {}, settings = {}) {
    this.title = account.title || ''
    this.titleRomanized = account.title_romanized || ''
    this.titleDative = account.title_dative || ''
    this.titleAccusative = account.title_accusative || ''
    this.titleGenitive = account.title_genitive || ''
    this.username = account.username || ''
    this.password = account.password || ''
    this.position = account.position || ''
    this.email = account.profile?.email || ''
    this.phone = account.profile?.phone || ''
    this.photo = account.profile?.photo || ''
    this.employer = account.profile?.employer || ''
    this.inn = account.profile?.inn || ''
    this.snils = account.profile?.snils || ''
    this.gender = account.profile?.gender || ''
    this.dob = UDate.formatDate(account.profile?.dob || '')
    this.citizenship = account.profile?.citizenship || ''
    this.passport = account.profile?.passport || ''
    this.locality = account.profile?.locality || ''
    this.address = account.profile?.address || ''
    this.physicalAddress = account.profile?.physical_address || ''
    this.postalAddress = account.profile?.postal_address || ''
    this.education = account.profile?.education || ''
    this.certificates = account.profile?.certificates || []
    this.timezone = settings.timezone || ''
    this.generatePassword = 0
    this.sendCredentials = false
  }
}

export default {
  name: 'TheUserRequiredFields',
  directives: { mask },
  components: {
    UPlaceholder
  },
  mixins: [documentTitle, innValidator],

  beforeRouteEnter (to, from, next) {
    const { id, profile } = store.getters[getters.ACCOUNT]
    if (!profile.can_change_himself) {
      next({ name: 'user', params: { userID: id } })
    }

    next()
  },

  data () {
    return {
      documentTitle: 'Для продолжения обучения заполните пожалуйста обязательные поля:',
      preparedFields: {},
      citizenshipItems: COUNTRIES,
      isSubmitting: false,
      loading: false,
      certificateLevels: [
        { text: 'Высшее образование', value: 'Высшее образование' },
        { text: 'Среднее профессиональное образование', value: 'Среднее профессиональное образование' },
        { text: 'Справка', value: 'Справка' },
        { text: 'Стаж', value: 'Стаж' },
        { text: 'Пункт 1 правил формирования ФИС ФРДО', value: 'Пункт 1 правил формирования ФИС ФРДО' }
      ],
      validateSNILS
    }
  },

  computed: {
    ...mapGetters({
      account: getters.ACCOUNT,
      vendor: getters.VENDOR,
    }),

    requiredFields () {
      return this.vendor.branding.required_listener_profile_fields || []
    },

    allFieldsFilled () {
      return Object.values(this.preparedFields).every(f => !f.isEmpty)
    },
  },

  created () {
    this.register()
  },

  methods: {
    ...mapActions({
      setAccount: actions.SET_ACCOUNT,
      createNotification: actions.CREATE_NOTIFICATION
    }),

    addCertificate () {
      this.preparedFields.previous_certificate.value = [...this.preparedFields.previous_certificate.value, {
        level: '',
        fullname: '',
        series: '',
        number: '',
        edu: '',
        edu_level: '',
        signed_at: '',
        key: this.preparedFields.previous_certificate.value.length
      }]
    },

    deleteCertificate (idx) {
      this.preparedFields.previous_certificate.value.splice(idx, 1)
      this.preparedFields.previous_certificate.value = [...this.preparedFields.previous_certificate.value]
    },

    async register () {
      this.loading = true

      try {
        const { user } = await getUser(this.account.id)
        const { settings } = await getUserSettings(this.account.id)

        if (!user) return

        this.setAccount(user)
        this.settings = settings
        this.profile = new Profile(this.account, this.settings)

        this.prepareFields()

        if (this.allFieldsFilled) {
          this.$router.push('/')
          return
        }

        if (!this.profile.certificates.length) {
          this.addCertificate()
        }
      } catch (e) {
        //
      } finally {
        this.loading = false
      }
    },

    updateINN (event) {
      this.preparedFields.inn.value = this.filterINN(event)
    },

    prepareFields () {
      this.preparedFields = {}
      for (const field of this.requiredFields) {
        let value, isEmpty
        if (field === 'previous_certificate') {
          value = this.profile.certificates || []
          isEmpty = !value.length
        } else {
          value = this.profile[field]
          isEmpty = !value
        }
        this.$set(this.preparedFields, field, { value, isEmpty })
      }
    },

    collectData () {
      const fields = this.preparedFields
      const formData = new FormData()

      const addCertificate = (certificate, idx) => {
        if (Object.values(certificate).some(value => !!value)) {
          formData.append('profile[certificate][key]', idx)
          formData.append('profile[certificate][level]', certificate.level || '')
          formData.append('profile[certificate][fullname]', certificate.fullname || '')
          formData.append('profile[certificate][series]', certificate.series || '')
          formData.append('profile[certificate][number]', certificate.number || '')
          formData.append('profile[certificate][signed_at]', certificate.signed_at || '')
          formData.append('profile[certificate][edu]', certificate.edu || '')
          formData.append('profile[certificate][edu_level]', certificate.edu_level || '')
        }
      }

      this.requiredFields.forEach(requiredField => {
        switch (requiredField) {
          case 'position':
            formData.append('position', fields.position.value)
            break
          case 'dob':
            formData.append('profile[person][dob]', UDate.formatDateISO(fields.dob.value))
            break
          case 'previous_certificate':
            this.preparedFields.previous_certificate.value.forEach(addCertificate)
            break
          default:
            formData.append(`profile[person][${requiredField}]`, fields[requiredField].value)
        }
      })

      return formData
    },

    async save () {
      const form = this.$refs.form
      if (!form || !form.validate()) {
        return this.createNotification({
          msg: 'Заполните все поля!',
          type: 'error'
        })
      }
      this.isSubmitting = true

      const { user } = await updateUser(this.account.id, this.collectData())
      this.setAccount(user)

      const { settings } = await getUserSettings(this.account.id)
      this.settings = settings
      this.profile = new Profile(this.account, this.settings)

      this.createNotification({
        msg: this.$t('$app.changes_saved'),
        type: 'success'
      })

      this.isSubmitting = false

      const route = this.$route?.query?._from
      if (route) {
        document.location.href = document.location.origin + route
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.user-edit {
  height: 100%;

  @media #{map-get($display-breakpoints, 'md-and-up')} {
    margin-top: 1rem;
  }
}
.user-edit__body {
  display: block;
  height: 100%;
  padding: 1rem;
}
</style>
